import React from 'react';
import '../src/css/App.css';
import PageWrapper from "./components/PageWrapper";
import Footer from "./components/Common/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";

//Pages
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Work from "./components/Pages/Work";
import Contact from './components/Pages/Contact';

function App() {
  return (
    <Router>
      <PageWrapper>
        <Route
          exact={true}
          path="/"
          component={Home}
        />

        <Route
          path="/about"
          component={About}
        />

        <Route
          path="/contact"
          component={Contact}
        />

        <Route
          path="/work"
          component={Work}
        />

      </PageWrapper>
      <Footer />
    </Router>
  );
}

export default App;
